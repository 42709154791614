const input = {
    username: 'اسم المستخدم',
    password: 'كلمة المرور',
    email: 'البريد الإلكتروني',
    phone_number: 'رقم الهاتف',
    confirm_password: 'تأكيد كلمة المرور',
    search_region_code: 'رمز منطقة البحث',
    backup_code: 'رمز النسخ الاحتياطي',
};
export default Object.freeze(input);
