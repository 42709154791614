const user_scopes = {
    descriptions: {
        custom_data: 'بياناتك المخصصة',
        email: 'عنوان بريدك الإلكتروني',
        phone: 'رقم هاتفك',
        profile: 'اسمك، اسم المستخدم، الصورة الرمزية ومعلومات الملف الشخصي الأخرى',
        roles: 'الأدوار الخاصة بك',
        identities: 'هوياتك الاجتماعية المرتبطة',
        'urn:logto:scope:organizations': 'معلومات منظماتك',
        'urn:logto:scope:organization_roles': 'أدوار المنظمة الخاصة بك',
        address: 'عنوانك',
    },
};
export default Object.freeze(user_scopes);
